<template>
  <div class="page-wrap">
    <div class="page-modal">
      <div class="modal-header">
        <em class="material-icons">warning</em>
        <div>notFoundPageHeader</div>
      </div>
      <div class="modal-content">
        notFoundPageContent
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="scss" scoped>
</style>
